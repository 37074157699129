import { useContext, useEffect, useState } from "react";

import heic2any from "heic2any";
import { AuthContext } from "../../../lib/auth/Context";
import useNotify from "../../../lib/hooks/Notify";
import useAPI from "../../../lib/hooks/useAPI";





export const useAvatar = (userId, companyId, setUser, setCompany,user, isCompany) => {
  const api = useAPI();

  const { state } = useContext(AuthContext);
  const [imgLoading, setImgLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [crop, setCrop] = useState({
    unit: "px", // default, can be 'px' or '%'
    x: 100,
    y: 50,
    width: 100,
    height: 100,
    minHeight: 100,
    minWidth: 100,
    aspect: 1,
  });
  const [src, setSrc] = useState(null);
  const [blobFile, setBlobFile] = useState(null);
  const [imageRef, setImageRef] = useState(null);

  const notify = useNotify();

  useEffect(() => {
    if (!imageRef) {
      return;
    }
    makeClientCrop(crop);
  }, [imageRef]); // eslint-disable-line react-hooks/exhaustive-deps

  let fileUrl;

  const onImageLoaded = (image) => {
    setImageRef(image);
  };

  const onCropComplete = (crop) => {
    makeClientCrop(crop);
  };

  const onCropChange = (crop, percentCrop) => {
    setCrop(crop);
  };

   

  const onPhotoChangeClick = (e) => {
    setImgLoading(true);
    const fileName = e.target.files[0].name;
    const fileNameExt = fileName
      .substr(fileName.lastIndexOf(".") + 1)
      .toLowerCase();
    if (fileNameExt === "heic") {
      const blob = e.target.files[0];
      heic2any({
        blob: blob,
        toType: "image/jpg",
      })
        .then((resultBlob) => {
          setImgLoading(false);
          const fileReader = new FileReader();
          fileReader.onloadend = () => {
            setSrc(fileReader.result);
          };
          fileReader.readAsDataURL(resultBlob);
        })
        .catch(function (x) {
          setImgLoading(false);
          notify({ title: x.code, description: x.message });
        });
    } else {
      setImgLoading(false);
      const fileReader = new FileReader();
      fileReader.onloadend = () => {
        setSrc(fileReader.result);
      };
      fileReader.readAsDataURL(e.target.files[0]);
    }
  };

  const makeClientCrop = async (crop) => {
    if (imageRef && crop.width && crop.height) {
      let fileName = `${userId ? userId : companyId}_avatar.jpeg`;
      fileName = state.id + fileName;

      if (process.env.NODE_ENV !== "production") {
        fileName = "local_" + fileName;
      }

      const url = await getCroppedImg(imageRef, crop, fileName);
      userId
        ? setUser(pv => ({ ...pv, photo: url }))
        : setCompany(pv => ({ ...pv, photo: url }));
    }
  };

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement("canvas");
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    const canvasResized = document.createElement("canvas");
    canvasResized.width = 100;
    canvasResized.height = 100;
    const ctxResized = canvasResized.getContext("2d");
    ctxResized.drawImage(canvas, 0, 0, 100, 100);

    return new Promise((resolve, reject) => {
      canvasResized.toBlob(
        (blob) => {
          if (!blob) {
            //reject(new Error('Canvas is empty'));
            console.error("Canvas is empty");
            return;
          }
          blob.name = fileName;
          setBlobFile(blob);
          window.URL.revokeObjectURL(fileUrl);
          fileUrl = window.URL.createObjectURL(blob);
          resolve(fileUrl);
        },
        "image/jpeg",
        1
      );
    });
  };


  const uploadFile = () => {
    const endpoint = isCompany ? 'company/photo/upload':'user/photo/upload';
    
    const data = new FormData();
    
    const reader = new FileReader()
    reader.readAsDataURL(blobFile)
    reader.onloadend = function() {
      const base64data = reader.result;
      data.append("files", blobFile);
      if (isCompany)   data.append("companyID", companyId ? companyId : user.id);  
      else   data.append("userID", userId ? userId : user.id);  
      
      
      api.postFile(endpoint, data).then(() => {
        setTimeout(() => {
          if (isCompany) {
            setCompany(pv => ({ ...pv, photo: base64data }))
          }
          else setUser(pv => ({ ...pv, photo: base64data }))
        },10)
        
        
        setSrc(null);
      });  
    }
  }
 
  return {
    onPhotoChangeClick: onPhotoChangeClick,
    onImageLoaded: onImageLoaded,
    onCropComplete: onCropComplete,
    onCropChange: onCropChange,
    uploadFile: uploadFile,
    progress: progress,
    src,
    crop,
    imgLoading,
    setSrc,
  };
};
